import React from 'react';
import { StyledH2, StyledParagraph } from '../styles/typography';
import { FormContent } from '../styles/global';
import BtnAction from './BtnAction';

const ConfirmComponent = ({ heading, paragraph }) => {
  
  const reloadPage = () => {
    window.location.reload();
  }

  return (
    <>
    <FormContent>
      <StyledH2>{heading}</StyledH2>
        <StyledParagraph>{paragraph}</StyledParagraph>
    </FormContent>
    <BtnAction
      onClick={() => reloadPage()} 
      revertStyle
    >
      Tillbaka
    </BtnAction>
    </>
  )
}
  
export default ConfirmComponent