import React from 'react';
import ForgotPassword from './pages/ForgotPassword'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ResetPassword from './pages/ResetPassword';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/forgot_password' component={ForgotPassword} />
        <Route path='/' component={ResetPassword} />
      </Switch>
    </Router>
  )};

export default App;