import styled from 'styled-components';
import { StyledParagraph } from './typography';

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.primary};
  border-color: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borderRadius.input};
  border-width: 1px;
  padding: ${({ theme }) => theme.space[0]};
  color: ${({ theme }) => theme.text};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  width: 100%;
  height: ${({ theme }) => theme.space[4]};
  box-sizing: border-box;
`;

export const InputParent = styled.div`
  position: relative;
`

export const ErrorMessage = styled.p`
  position: absolute;
  top: 42px;
  color: ${({ theme }) => theme.error};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

export const Wrapper = styled.main`
  max-width: 400px;
  margin: 40px auto;
`;

export const FormContent = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]};
`

export const ServerError = styled(StyledParagraph)`
  padding-bottom: ${({ theme }) => theme.space[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  line-height: 1.5;
  margin: 0;
`