import React, { useState, useRef } from 'react';
import axios from 'axios'
import { useForm } from 'react-hook-form';
import CircleLoader from '../components/CircleLoader';
import ErrorComponent from '../components/ErrorComponent';
import HeadingSection from '../components/HeadingSection';
import Card from '../components/Card';
import BtnAction from '../components/BtnAction';
import { 
  StyledInput,
  ErrorMessage,
  FormContent,
  InputParent,
  Wrapper
 } from '../styles/global';
import { StyledH3 } from '../styles/typography';
import { useLocation } from "react-router-dom";

const ResetPassword = () => {
  let location = useLocation();
  let url = location.search
  let code = url.replace('?code=','');

  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");

  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState(false)

  const onSubmit = async (formData) => {  
    setIsLoading(true);
    const apiClient = axios.create({
      baseURL: 'https://duty24.herokuapp.com/',
      responseType: 'json',
    })
  
    try {
      let payload = {
        code: code,
        password: formData.password,
        passwordConfirmation: formData.passwordConfirmation
      }
      const response = await apiClient.post(
        '/auth/reset-password', payload
      )
      const data = response
      if (data.status !== 200) {
        setIsLoading(false);
        setServerError('Något gick fel. Testa igen.')
      }
      setIsLoading(false);
      setConfirm(true)
    } catch(error) {
      setServerError('Något gick fel. Testa igen.')
      setIsLoading(false);
    }
  }

  return (
      <Wrapper>
        <Card>
        {serverError ?
          <ErrorComponent serverError={serverError} />
        : !serverError && confirm ?
        <FormContent>
          <HeadingSection 
            heading={'Tack!'}
            paragraph={'Ditt lösenord är nu ändrat.'}
          />
          &nbsp;
        </FormContent>
        :
          <form onSubmit={e => e.preventDefault()}>
            <FormContent>
              <HeadingSection 
                heading={'Nytt lösenord'}
                paragraph={'Fyll i ett nytt lösenord och verifiera lösenordet.'}
              />
              <StyledH3>Lösenord*</StyledH3>
              <InputParent>
                <StyledInput
                  name="password"
                  type="password"
                  ref={register({
                    required: "Ange lösenord.",
                    minLength: {
                      value: 8,
                      message: "Lösenordet måste innehålla minst 8 karaktärer."
                    }
                  })}
                />
                {errors.password ? <ErrorMessage>{errors.password.message}</ErrorMessage> : null}
              </InputParent>

              <StyledH3>Verifiera lösenord*</StyledH3>
              <InputParent>
                <StyledInput
                  name="passwordConfirmation"
                  type="password"
                  ref={register({
                    validate: value =>
                      value === password.current || "Lösenorden matchar inte."
                  })}
                />
                {errors.passwordConfirmation ? <ErrorMessage>{errors.passwordConfirmation.message}</ErrorMessage> : null}
              </InputParent>
            </FormContent>
    
            {isLoading ? 
              <CircleLoader />
            :
              <BtnAction type="submit" onClick={handleSubmit(onSubmit)}>Skicka</BtnAction>
            }
            
          </form> 
        }
        </Card>
      </Wrapper>
  );
};

export default ResetPassword;