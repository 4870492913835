import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useForm } from 'react-hook-form';
import CircleLoader from '../components/CircleLoader';
import ErrorComponent from '../components/ErrorComponent';
import ConfirmComponent from '../components/ConfirmComponent';
import HeadingSection from '../components/HeadingSection';
import Card from '../components/Card';
import BtnAction from '../components/BtnAction';
import { 
  StyledInput,
  ErrorMessage,
  FormContent,
  InputParent,
  Wrapper
 } from '../styles/global';
 import { StyledH3 } from '../styles/typography';


const ForgotPassword = () => {
  const { register, handleSubmit, errors } = useForm({});
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState(false)

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const apiClient = axios.create({
      baseURL: 'https://duty24.herokuapp.com/',
      responseType: 'json',
    })
    try {
      let payload = {
        email: formData.email,
      }
      const response = await apiClient.post(
        '/auth/forgot-password', payload
      )
      const data = response
      if (data.status !== 200) {
        setIsLoading(false);
        setServerError('Mejladressen du angav finns inte registrerad hos 24duty.')
      }
      setIsLoading(false);
      setConfirm(true)
    } catch(error) {
      setServerError('Mejladressen du angav finns inte registrerad hos 24duty.')
      setIsLoading(false);
    }
  }
  
  return (
    <>
      <Wrapper>
        <Card>
        {serverError ?
          <ErrorComponent serverError={serverError} />
        : !serverError && confirm ?
          <ConfirmComponent
            heading={'Kolla din mejl'}
            paragraph={'Ett meddelande med en länk för att återställa lösenordet har skickats till din mejl. Har den inte kommit fram? Kolla också i din skräppost.'}
          />
        :
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
              <HeadingSection 
                heading={'Ändra lösenord'}
                paragraph={'Fyll i mejladressen som ditt 24duty-konto är registrerat på.'}
              />
              <StyledH3>E-post*</StyledH3>
              <InputParent>
                <StyledInput
                  type="text"
                  name="email"
                  ref={register({
                    required: "Ange e-post adress",
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&‘*+\=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: 'Ange en giltig e-post adress',
                    },
                  })}
                />
                {errors.email ? <ErrorMessage>{errors.email.message}</ErrorMessage> : null}
              </InputParent>
            </FormContent>
    
            {isLoading ? 
              <CircleLoader />
            :
              <BtnAction type="submit">Skicka</BtnAction>
            }
            
          </form> 
        }
        </Card>
      </Wrapper>

    </>
  );
};

export default ForgotPassword;